import React from 'react';
import { graphql } from 'gatsby';
import CoverImageTitle from '../components/coverImageTitle';
import DescriptionsPage from '../components/descriptionsPage';
import DataImpressumPage from '../components/dataImpressumPage';

const ImpressumTemplate = (props) => {
  const {
    data: {
      wordpressPage: {
        acf: {
          title,
          background_image: {
            localFile: {
              childImageSharp: {
                fluid: background_image,
              },
            },
          },
          grid_2x_infobox,
          Articles_List: {
            articles,
            articles_list_title,
          },
        },
      },
    },
  } = props;

  return (
    <div className="main-container-impressum">
      <CoverImageTitle title={title} fluid={background_image} />
      <DataImpressumPage descriptionsPages={grid_2x_infobox} />
      <div className="second-description-title-impressum">
        <h2 className="title">{articles_list_title}</h2>
      </div>
      <DescriptionsPage seconddescription={articles} />
    </div>

  );
};

export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: { eq: "impressum" }
      wpml_current_locale: { eq: $lang }
    ) {
      title
      path
      yoast_head
      acf {
        title
      background_image {
        localFile {
          childImageSharp {
            fluid(maxHeight: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      grid_2x_infobox {
        box_title
        box_content
      }
      Articles_List {
        articles {
          article_title
          article_content
        }
        articles_list_title
      }
   
      }
    }
    wordpressAcfOptions {
    options {
      kontakt {
        de_label
        en_label
      }
      produkte {
        de_label
        en_label
      }
      referenzen {
        de_label
        en_label
      }
      sprache {
        de_label
        en_label
      }
      copyright_text
      contact_icons_footer {
        email
        phone
      }
      bottom_footer_right {
        name
        english_name
        url
      }
      bottom_footer_left {
        name
        english_name
        url
      }
    }
  }
  }
`;
export default ImpressumTemplate;
