import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const DataImpressumPage = ({ descriptionsPages }) => (
  <div className="container data">
    <div className="row">
      {descriptionsPages
        && descriptionsPages.map((description) => (
          <div
            className="col-md-6"
            key={ReactHtmlParser(description.box_title)}
          >
            <div className="col-md-12">
              <h2 className="title">
                {ReactHtmlParser(description.box_title)}
              </h2>
              <p
                className="info-data"
                dangerouslySetInnerHTML={{ __html: description.box_content }}
              />
            </div>
          </div>
        ))}
    </div>
  </div>
);

DataImpressumPage.propTypes = {
  descriptionsPages: PropTypes.shape({
    box_title: PropTypes.string,
    box_content: PropTypes.string,
  }).isRequired,
};

export default DataImpressumPage;
